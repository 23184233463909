// Redux imports
// ================================================================
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';

// Import Store modules
// ================================================================
import APP, { setSessionStatus, networkError } from './App';
import NOTIFICATIONS from './Notifications';
import { API_STORE_KEY } from '../const';

// Import middlewares
// ================================================================
import asyncMiddleware from './middleware/async';
import normalizationMiddleware from './middleware/normalize';

// Redux store factory, requires api provider and set of modules
// ================================================================
export default function createAppStore({
  modules,
  api,
  apiv2,
  network_error_event_key
}) {
  let normalizerConfig = {};

  // Parse module config compose root reducer, populate normalize config
  // ================================================================
  const reducers = [APP, NOTIFICATIONS, ...modules].reduce(
    (acc, { reducer, dataschema, key }) => {
      // Add schema to normalize config if provided
      // ================================================================
      if (dataschema) {
        normalizerConfig = { ...normalizerConfig, ...dataschema };
      }

      acc[key] = reducer;
      return acc;
    },
    {}
  );

  // Redux devtools compose replacement
  // ================================================================
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Create store instance
  // ================================================================
  const store = createStore(
    combineReducers(reducers),
    {},
    composeEnhancers(
      applyMiddleware(
        asyncMiddleware({
          api,
          apiv2
        }),
        normalizationMiddleware(normalizerConfig)
      )
    )
  );

  // Assign API to defined prop in Redux store object
  store[API_STORE_KEY] = {
    api,
    apiv2
  };

  // Error handler, detect 401 error separately
  // ================================================================
  window.addEventListener(network_error_event_key, (evt) => {
    if (evt.session) {
      store.dispatch(networkError({ title: evt.name, text: evt.message }));
    } else {
      store.dispatch(setSessionStatus(false));
    }
  });

  return store;
}
