import { AsyncAction } from '../action';

// Provide extra arguments through closure
// ================================================================
export default function asyncMiddleware({ api, apiv2 }) {
  return ({ getState }) =>
    (next) =>
    async (action) => {
      // Check if payload is executable
      // ================================================================
      if (action instanceof AsyncAction) {
        // Execute payload creator with api injected
        // ================================================================
        const result = await action.execute({
          api,
          apiv2,
          getState
        });
        // Proceed with a results
        // ================================================================
        return next(result);
      } else {
        // If payload not a function just proceed
        // ================================================================
        return next(action);
      }
    };
}
