import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import lib from './library.json';
import assignDisplayName from '../util/assignDisplayName';

export const NOT_FOUND_ICON = 'icon-not-found';
const NOT_FOUND_COLOR = '#fb2f2f';

export default function Icon({ icon, viewBox = '0 0 20 20', className }) {
  const d = icon.startsWith('path:') ? icon.replace('path:', '') : lib[icon];
  return (
    <svg
      data-icon
      aria-hidden
      viewBox={viewBox}
      className={cn('Icon', className)}
    >
      {d ? (
        <path d={d} />
      ) : (
        <path d={lib[NOT_FOUND_ICON]} fill={NOT_FOUND_COLOR} />
      )}
    </svg>
  );
}

assignDisplayName(Icon);

Icon.propTypes = {
  /** CSS class for <svg> element */
  className: PT.string,
  /** sets up viewbox attr for SVG, which acts as canvas */
  viewBox: PT.string,
  /** Icon name acts as key to search lib for path, or path itself if it prefixed `path:` */
  icon: PT.string.isRequired
};
