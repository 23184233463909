// Required libraries
// ================================================================
import React from 'react';
import { useSelector } from 'react-redux';

// Utility functions and hooks
// ================================================================
import { useReduxAction } from '../hooks';
import { NOTIFICATION_TIMEOUT } from '../const';

// Store imports
// ================================================================
import { getNotifications, hideNotification } from '../Store/Notifications';

// Component to render
// ================================================================
import Notifications from '../Components/Notifications';

// Component declaration
// ================================================================
export default function NotificationsModule() {
  // Get notifications data
  // ================================================================
  const data = useSelector(getNotifications);

  // Close notification on click
  // ================================================================
  const onClick = useReduxAction(hideNotification);

  return (
    <Notifications data={data} onClick={onClick} delay={NOTIFICATION_TIMEOUT} />
  );
}
