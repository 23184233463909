import { NOTIFICATION_TYPES } from 'trendolizer-ui/build/const';
import createAction from './action';
import createReducer from './reducer';
import { getStore } from './selector';
import { NOTIFICATION_MAX_COUNT } from '../const';
import { APP, NOTIFICATION } from './types';

// Initial "blank" state
// ================================================================
const blankNotification = {
  id: null,
  show: true,
  type: 'info',
  title: '',
  text: ''
};

// Actions
// ================================================================
export const addNotification = createAction(NOTIFICATION.ADD);

export const hideNotification = createAction(NOTIFICATION.HIDE);

// This function clamps array to keep it no longer that given limit
// Prevent "overwhelming" amount of notifications being stored
// ================================================================
const addEntry = (state, payload) => {
  const newState = [
    {
      ...blankNotification,
      id: 1 + state.length,
      ...payload
    },
    ...state
  ];
  if (newState.length > NOTIFICATION_MAX_COUNT) {
    newState.length = NOTIFICATION_MAX_COUNT;
  }
  return newState;
};

// Default module export
// ================================================================
const notificationSchema = {
  id: 'number',
  show: 'bool',
  type: `oneof|${Object.values(NOTIFICATION_TYPES).join(',')}`,
  title: '!string',
  text: 'string'
};

export default {
  key: NOTIFICATION.KEY,
  // Schema of entity for data normalization
  // ================================================================
  dataschema: {
    [NOTIFICATION.ADD]: notificationSchema,
    [NOTIFICATION.HIDE]: { id: '!number' },
    [APP.NETWORK_ERROR]: {
      title: '!string',
      text: '!string'
    }
  },
  // Reducer function
  // ================================================================
  reducer: createReducer(
    {
      [NOTIFICATION.ADD]: (state, payload) => addEntry(state, payload),
      [NOTIFICATION.HIDE]: (state, { id }) =>
        state.map((item) => ({
          ...item,
          show: item.id === id ? false : item.show
        })),
      [APP.NETWORK_ERROR]: (state, payload) =>
        addEntry(state, {
          ...payload,
          type: NOTIFICATION_TYPES.ERROR
        }),
      default: (state, { message }) => {
        if (message) {
          return addEntry(state, {
            type: NOTIFICATION_TYPES.SUCCESS,
            text: message,
            title: 'Operation successfull'
          });
        }
        return state;
      }
    },
    []
  )
};

// Selectors
// ================================================================
export const getNotificationStore = getStore(NOTIFICATION.KEY);

export const getNotifications = (state) =>
  getNotificationStore(state).filter(({ show }) => show);
