import { get as getCssVar } from 'trendolizer-ui/build/util/cssVar';
import isEmail from 'trendolizer-ui/build/util/isEmail';
import isValidString from 'trendolizer-ui/build/util/isValidString';
import {
  RECIPIENT_TYPE_EMAIL,
  RECIPIENT_TYPE_SLACK,
  RECIPIENT_TYPE_TELEGRAM,
  DASHBOARD_PARAM,
  DASHBOARD_URL
} from './const';

/**
 * Create new window with fixed dimensions and provide content to print it
 * @param {Element} el Dom element, it's html would be used as a content
 * @param {String} title Optional title, would be used as a title of window and inserted as <h1> at the beginning
 */
export const printContent = (el, title) => {
  if (!el) {
    throw new Error('Content element not found, nothing to print');
  }
  const frame = window.open(
    '',
    '',
    'left=0,top=0,width=630,height=891,toolbar=0,scrollbars=0,status=0,popup'
  );
  frame.document.write(`
  <html>
    <head>
      <title>${title}</title>
      <link rel="stylesheet" href="css/print.css">
    </head>
    <body>
      <h1>${title}</h1>
      <hr/>
      ${el.innerHTML}
    </body>
  </html>
  `);
  frame.document.close();
  frame.focus();
  frame.print();
  frame.onafterprint = frame.close;
};

/**
 * Helper that iterates over array of id given and replaces it with corresponding data from source
 * @param {Object} source Collection of data by ID
 * @param {Array(Number)} ids Array of ID as a numbers
 * @returns {Array(Object)} Array of objects extracted by id given
 */
export const mapArrayToData = (source, ids) =>
  ids.reduce((acc, id) => {
    if (source[id]) {
      acc.push(source[id]);
    }
    return acc;
  }, []);

export const mergeArray = (target, ...rest) => [
  ...new Set(target.concat(...rest))
];

export const castStrToOption = (val) => ({ value: val, label: val });

export const getRecipientType = (val) => {
  if (isEmail(val)) return RECIPIENT_TYPE_EMAIL;
  else if (val.indexOf('hooks.slack') > -1) return RECIPIENT_TYPE_SLACK;
  else if (val.indexOf('t.me') > -1) return RECIPIENT_TYPE_TELEGRAM;
  else return null;
};

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export const getDashboardIdFromLocation = () => {
  const { pathname, searchParams } = new URL(window.location);
  if (pathname.startsWith(DASHBOARD_URL)) {
    return parseInt(searchParams.get(DASHBOARD_PARAM)) || 0;
  }
  return 0;
};

export const localStorageGet = (key, fallback) => {
  const value = window.localStorage.getItem(key);
  if (isValidString(value)) {
    return JSON.parse(value);
  }
  return fallback;
};

export const localStorageSet = (key, val) =>
  window.localStorage.setItem(key, JSON.stringify(val));

export const onLocalStorageUpdate = (key, func) => {
  const listener = (event) => {
    if (event.key === key) {
      func(JSON.parse(event.newValue));
    }
  };
  window.addEventListener('storage', listener);
  return () => window.removeEventListener('storage', listener);
};

export const getNumericalCssValue = (val, fallback = 0) =>
  parseInt(getCssVar(val) || fallback);

export function decodeHtml() {
  const txt = document.createElement('textarea');
  return (html) => {
    txt.innerHTML = html;
    return txt.value;
  };
}

export const truncateText = (string, from = 0, max = 1) => {
  if (string.length < max) return string;
  const lastSpace = string.indexOf(' ', from);
  return string.substring(0, lastSpace > max ? max : lastSpace) + '...';
};

export const devDebug = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  } else {
    // No debug here
  }
};

export const addTypographyDebug = () => {
  if (process.env.NODE_ENV === 'development') {
    document.body.addEventListener(
      'dblclick',
      ({ target, ctrlKey, shiftKey }) => {
        if (ctrlKey && shiftKey) {
          if (!target.classList.contains('debug-typography-rhythm')) {
            target.classList.add('debug-typography-rhythm');
          } else {
            target.classList.remove('debug-typography-rhythm');
          }
        }
      }
    );
  }
};

export const pickUnfetchedUsers = (
  data,
  { overscanStartIndex, overscanStopIndex }
) => {
  const ids = new Set();
  for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
    let item = data[i];
    if (item && item.owner_id && (!item.owner || !item.owner.id)) {
      ids.add(item.id);
    }
  }
  return [...ids];
};

export const numToStringInterval = (num) => {
  let interval = num / 60;
  if (interval > 60) {
    interval = `every ${interval / 60} hours.`;
  } else if (interval === 60) {
    interval = 'every 1 hour.';
  } else {
    interval = `every ${interval} mins.`;
  }
  return interval;
};

export const trimFeedUrl = (str) =>
  str.replace(/^.+:(\s|\/\/)?/, '').replace(/\/$/, '');

export const copyToClipboard = (value) => {
  if (window.navigator && window.navigator.clipboard) {
    window.navigator.clipboard.writeText(value);
  } else {
    const $el = document.createElement('textarea');
    $el.style.cssText = 'opacity:0;position:fixed;bottom:100%;';
    $el.value = value;
    document.body.appendChild($el);
    $el.select();
    document.execCommand('copy');
    document.body.removeChild($el);
  }
};

export const addCollectionToObject = (obj, key, item) => {
  if (obj[key]) {
    obj[key].push(item);
  } else {
    obj[key] = [item];
  }
  return obj;
};

export const delay = (t) => new Promise((res) => setTimeout(res, t));

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const cssClassBlink = async ($el, cls, t) => {
  $el.classList.add(cls);
  await delay(t);
  $el.classList.remove(cls);
};

export const emitCustomEvent = (e, detail, t = document.body) => {
  t.dispatchEvent(
    new CustomEvent(e, {
      detail
    })
  );
};
