// Required libraries
// ================================================================
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PT from 'prop-types';
import cn from 'classnames';

// Utility functions and hooks
// ================================================================
import omit from 'trendolizer-ui/build/util/omit';
import isValidString from 'trendolizer-ui/build/util/isValidString';
import { MULTI_DISPLAY_PARAMS } from 'shared/DisplaySettings';
import { useAction, useReduxAction } from 'shared/hooks';

// Store imports
// ================================================================
import {
  fetchDetailedResult,
  clearDetails,
  getFullResult
} from 'shared/Store/Results';

// Trendolizer UI imports
// ================================================================
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Icon from 'trendolizer-ui/build/Icon';
import Image from 'trendolizer-ui/build/Image';
import Dialog from 'trendolizer-ui/build/Dialog';
import Toggler from 'trendolizer-ui/build/Toggler';

import StatusMessage from 'shared/Components/StatusMessage';
import { EntitySource } from 'shared/Components/Entity';
import ResultGraphs from 'shared/Modules/ResultGraphs';

// Styles
// ================================================================
import './style.css';

const tableValue = (v) => (Number.isInteger(v) ? v : 0);

const tableHotness = (v) => v || '0.000000';

const LINK_REGEX = /http[^\s]+/g;

const wrapInTag = (match) =>
  `<a href="${match}" target="_blank" ref="noreferrer">${match}</a>`;

// Other components render
// ================================================================

const getHash = () => window.location.hash.replace('#', '');

const initFields = MULTI_DISPLAY_PARAMS.reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {});

const initGraphTypes = {
  nocount: false,
  norate: false,
  noaverage: false,
  nomovingaverage: false,
  nochangerate: false
};

// Close dialog handler. Reset page hash
// ================================================================
const closeHandler = () => {
  window.location.hash = '';
};

// Component declaration
// ================================================================
export default function FullResultDialog(props) {
  const { dialogId, className } = props;

  const $descr = useRef();

  const [hash, setHash] = useState(getHash());

  const [graphFields, setGraphFields] = useState(initFields);

  const updateGraphFields = useCallback((e) => {
    e.preventDefault();
    const key = e.target.getAttribute('href');
    e.target.blur();
    setGraphFields((old) => {
      if (old[key]) return omit(old, key);
      return { ...old, [key]: true };
    });
  }, []);

  const [graphTypes, setGraphTypes] = useState(initGraphTypes);

  const updateGraphTypes = useCallback(
    (target) =>
      setGraphTypes((old) => ({ ...old, [target.name]: !target.checked })),
    []
  );

  const dispatch = useDispatch();
  const fetchHandler = useCallback(
    async (params) => {
      const { payload } = await dispatch(fetchDetailedResult(params));
      setGraphFields(
        MULTI_DISPLAY_PARAMS.reduce(
          (acc, key) => {
            if (payload[key] > 0 || payload[`rate_${key}`] > 0) {
              acc[key] = true;
            }
            return acc;
          },
          { ...initFields }
        )
      );
      return { payload };
    },
    [dispatch]
  );
  const clearHandler = useReduxAction(clearDetails);

  const data = useSelector(getFullResult());
  const description = data ? data.description : null;

  // Async form logic handling
  // ================================================================
  const [runFetchData, { loading, error, success, reset }] =
    useAction(fetchHandler);

  // Fetch request effect
  // ================================================================
  useEffect(() => {
    if (isValidString(hash)) {
      runFetchData({ hash });
    }
    return () => {
      if (isValidString(hash)) {
        reset();
        clearHandler();
        setGraphFields(initFields);
        setGraphTypes(initGraphTypes);
      }
    };
  }, [hash, runFetchData, reset, clearHandler]);

  // Hash event listener effect
  // ================================================================
  useEffect(() => {
    const listener = () => setHash(getHash);

    window.addEventListener('hashchange', listener);
    return () => window.removeEventListener('hashchange', listener);
  }, [runFetchData]);

  // Wrap all found links in a description in <a> tag
  // ================================================================
  useLayoutEffect(() => {
    if (description && $descr && $descr.current) {
      $descr.current.innerHTML = $descr.current.innerHTML.replace(
        LINK_REGEX,
        wrapInTag
      );
    }
  }, [description]);

  return (
    <Dialog
      id={dialogId}
      show={!!hash.length}
      className={cn('FullResult', className)}
      maxWidth='980px'
      maxHeight='90vh'
      onClose={closeHandler}
      title='Result details'
      header={<LinearProgress loading={loading} success={success} />}
    >
      {error ? <StatusMessage warning={error} /> : null}
      {data ? (
        <article className='FullResult-content'>
          <h1 className='FullResult-title'>
            <a href={data.url} target='_blank' rel='noreferrer'>
              {data.type === 'youtube' ? (
                <Icon
                  icon='youtube'
                  className='FullResult-type-icon type-youtube'
                />
              ) : null}
              {data.title}
            </a>
          </h1>
          <Image src={data.image} className='FullResult-media' />
          <dl className='FullResult-params'>
            <dt>Url</dt>
            <dd>{data.url}</dd>
            <dt>Domain</dt>
            <dd>{data.domain}</dd>
            <dt>Author</dt>
            <dd>{data.author}</dd>
            <dt>Found</dt>
            <dd>{data.found}</dd>
            <dt>Language</dt>
            <dd>{data.language}</dd>
          </dl>
          <div className='FullResult-description' ref={$descr}>
            {description}
          </div>
          <h4 className='FullResult-subtitle'>
            Link found in following sources
          </h4>
          <ul className='FullResult-sourceslist'>
            {data.sources.map(({ name, url }) => (
              <EntitySource
                key={url}
                id={url}
                name={url}
                type={name.split(': ').shift()}
              />
            ))}
            {data.sourceOverCount ? (
              <li className='more-counter'>
                And {data.sourceOverCount} more sources...
              </li>
            ) : null}
          </ul>
          <h4 className='FullResult-subtitle'>Full stats overview</h4>
          <table className='FullResult-stats'>
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Total</th>
                <th>Rate</th>
                <th>Maxrate</th>
                <th>Hotness</th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={cn({
                  'state-deselected': !graphFields.likes
                })}
                data-key='likes'
              >
                <td>
                  <a
                    href='likes'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Likes
                  </a>
                </td>
                <td>{tableValue(data.likes)}</td>
                <td>{tableValue(data.rate_likes)}</td>
                <td>{tableValue(data.maxrate_likes)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_likes)}
                </td>
              </tr>
              {/*
                <tr
                className={cn({
                  'state-deselected': !graphFields.shares
                })}
                data-key='shares'
              >
                <td>
                  <a
                    href='shares'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Shares
                  </a>
                </td>
                <td>{tableValue(data.shares)}</td>
                <td>{tableValue(data.rate_shares)}</td>
                <td>{tableValue(data.maxrate_shares)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_shares)}
                </td>
              </tr>
              */}
              <tr
                className={cn({
                  'state-deselected': !graphFields.comments
                })}
                data-key='comments'
              >
                <td>
                  <a
                    href='comments'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Comments
                  </a>
                </td>
                <td>{tableValue(data.comments)}</td>
                <td>{tableValue(data.rate_comments)}</td>
                <td>{tableValue(data.maxrate_comments)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_comments)}
                </td>
              </tr>
              <tr
                className={cn({
                  'state-deselected': !graphFields.tweets
                })}
                data-key='tweets'
              >
                <td>
                  <a
                    href='tweets'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Tweets
                  </a>
                </td>
                <td>{tableValue(data.tweets)}</td>
                <td>{tableValue(data.rate_tweets)}</td>
                <td>{tableValue(data.maxrate_tweets)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_tweets)}
                </td>
              </tr>
              <tr
                className={cn({
                  'state-deselected': !graphFields.pins
                })}
                data-key='pins'
              >
                <td>
                  <a
                    href='pins'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Pins
                  </a>
                </td>
                <td>{tableValue(data.pins)}</td>
                <td>{tableValue(data.rate_pins)}</td>
                <td>{tableValue(data.maxrate_pins)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_pins)}
                </td>
              </tr>
              <tr
                className={cn({
                  'state-deselected': !graphFields.votes_video
                })}
                data-key='votes_video'
              >
                <td>
                  <a
                    href='votes_video'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Video votes
                  </a>
                </td>
                <td>{tableValue(data.votes_video)}</td>
                <td>{tableValue(data.rate_votes_video)}</td>
                <td>{tableValue(data.maxrate_votes_video)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_votes_video)}
                </td>
              </tr>
              <tr
                className={cn({
                  'state-deselected': !graphFields.views_video
                })}
                data-key='views_video'
              >
                <td>
                  <a
                    href='views_video'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Video views
                  </a>
                </td>
                <td>{tableValue(data.views_video)}</td>
                <td>{tableValue(data.rate_views_video)}</td>
                <td>{tableValue(data.maxrate_views_video)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_views_video)}
                </td>
              </tr>
              <tr
                className={cn({
                  'state-deselected': !graphFields.comments_video
                })}
                data-key='comments_video'
              >
                <td>
                  <a
                    href='comments_video'
                    className='FullResult-tableanchor'
                    onClick={updateGraphFields}
                  >
                    Video comments
                  </a>
                </td>
                <td>{tableValue(data.comments_video)}</td>
                <td>{tableValue(data.rate_comments_video)}</td>
                <td>{tableValue(data.maxrate_comments_video)}</td>
                <td className='hotness-gauge1'>
                  {tableHotness(data.hotness_comments_video)}
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className='FullResult-subtitle'>Over-time data overview</h4>
          <ResultGraphs
            hash={hash}
            fields={Object.keys(graphFields).join(',')}
            nocount={graphTypes.nocount}
            norate={graphTypes.norate}
            noaverage={graphTypes.noaverage}
            nomovingaverage={graphTypes.nomovingaverage}
            nochangerate={graphTypes.nochangerate}
          />
          <form className='FullResult-graph-types'>
            <Toggler
              type='checkbox'
              label='Count'
              name='nocount'
              disabled
              id='nocount'
              value={true}
              checked={!graphTypes.nocount}
              onChange={updateGraphTypes}
            />
            <Toggler
              type='checkbox'
              label='Rate'
              name='norate'
              id='norate'
              value={true}
              checked={!graphTypes.norate}
              onChange={updateGraphTypes}
            />
            <Toggler
              type='checkbox'
              label='Average'
              name='noaverage'
              id='noaverage'
              value={true}
              checked={!graphTypes.noaverage}
              onChange={updateGraphTypes}
            />
            <Toggler
              type='checkbox'
              label='Moving average'
              name='nomovingaverage'
              id='nomovingaverage'
              value={true}
              checked={!graphTypes.nomovingaverage}
              onChange={updateGraphTypes}
            />
            <Toggler
              type='checkbox'
              label='Change rate'
              name='nochangerate'
              id='nochangerate'
              value={true}
              checked={!graphTypes.nochangerate}
              onChange={updateGraphTypes}
            />
          </form>
        </article>
      ) : null}
    </Dialog>
  );
}

// Proptypes declaration
// ================================================================
FullResultDialog.propTypes = {
  dialogId: PT.string.isRequired,
  className: PT.string
};
