// Required libraries
// ================================================================
import React from 'react';
// import PT from 'prop-types';
import { useSelector } from 'react-redux';
import { useReduxAction } from '../hooks';
import { AUTHENTICATION_URL } from '../const';

// User Store imports
// ================================================================
import { logIn } from '../Store/Tenant';

// Application Store imports
// ================================================================
import { getSessionStatus /* setSessionStatus */ } from '../Store/App';

// Component to render
// ================================================================
import LoginModal from '../Components/LoginModal';

// Close this modal handler
// ================================================================
const closeModal = () => {
  window.location.replace(AUTHENTICATION_URL);
};

// Component declaration
// ================================================================
export default function LoginDialogModule() {
  // Get session status
  // ================================================================
  const session = useSelector(getSessionStatus);

  // Dispatch submit handler
  // ================================================================
  const onSubmit = useReduxAction(logIn);

  return (
    <LoginModal
      session={session}
      title='Authentication required'
      description='Looks like session was timed-out or lost. Re-login please to
      continue work.'
      onClose={closeModal}
      onSubmit={onSubmit}
    />
  );
}
