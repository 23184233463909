// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PT from 'prop-types';

// Store imports
// ================================================================
import { getColumn, fetchColumn } from 'shared/Store/Columns';
import { fetchResults } from 'shared/Store/Results';

// Component to render
// ================================================================
import Column from '../Column';
import ResultList from '../ResultList';

const mapColumn = ({ id, name, data }) => ({
  id,
  name,
  sort: data.sort,
  search: data.search || '',
  direction: data.direction,
  infinite: data.infinite,
  autoreload: data.autoreload
});

// Component declaration
// ================================================================
export default function ColumnContainer(props) {
  const { id, width, onRemoveClick, onDeleteClick } = props;

  // Get Column data, and dispatch
  // ================================================================
  const item = useSelector(getColumn(id, mapColumn));
  const dispatch = useDispatch();

  // Result processing state
  // 0 - idle, 1 - reloading, 2 - load more, string - error
  // ================================================================
  const [state, setState] = useState(0);

  // Dispatch API call to fetch results
  // ================================================================
  const runFetchResults = useCallback(
    async ({ offset, init } = {}) => {
      try {
        setState(offset ? 2 : 1);
        await dispatch(fetchResults({ column: id, offset, init }));
        setState(0);
      } catch (err) {
        setState(err.toString());
      }
    },
    [dispatch, id]
  );

  // Refresh column and fetch results on demand
  // ================================================================
  const refershResults = useCallback(async () => {
    try {
      setState(1);
      // await dispatch(fetchColumn({ id }));
      await dispatch(fetchResults({ column: id }));
      setState(0);
    } catch (err) {
      setState(err.toString());
    }
  }, [dispatch, id]);

  return item ? (
    <Column
      data={item}
      refreshResults={refershResults}
      onRemoveClick={onRemoveClick}
      onDeleteClick={onDeleteClick}
    >
      <ResultList
        id={id}
        loading={state === 2}
        reloading={state === 1}
        idle={state === 0}
        infinite={!!item.infinite}
        search={item.search}
        sort={item.sort}
        error={typeof state === 'string' ? state : null}
        width={width}
        fetchResults={runFetchResults}
      />
    </Column>
  ) : null;
}

// PropTypes Declaration
// ================================================================
ColumnContainer.propTypes = {
  /** Unique ID of Column */
  id: PT.number.isRequired,
  /** Width for a results grid */
  width: PT.number,
  /** Handler for Delete button */
  onDeleteClick: PT.func.isRequired,
  /** Handler for Remove button */
  onRemoveClick: PT.func
};
