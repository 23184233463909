import arrayMove from 'array-move';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';

import {
  DASHBOARD_URL,
  DASHBOARD_PARAM,
  ORDER_KEY,
  DATA_KEY,
  DASHBOARD_MAX_COUNT,
  DB_DASHBOARDS_KEY,
  DEFAULT_DASHBOARD
} from '../const';
import { getDashboardIdFromLocation } from '../util';

import { COLUMN, DASHBOARD } from './types';
import createReducer, {
  updateKey,
  defaultUnsetOne,
  defaultSetOne
} from './reducer';
import { getStore, getAll, getOne } from './selector';
import createAction from './action';

import { getColumnsById } from './Columns';

// Initial "blank" state
// ================================================================
const BLANK_DASHBOARD = {
  id: null,
  name: '',
  abbr: '',
  label: 'darkred',
  description: '',
  current: false,
  width: 380,
  columns: []
};

const composeDashboard = (data) => ({
  ...BLANK_DASHBOARD,
  ...data,
  columns: Number.isInteger(data.columns) ? [data.columns] : data.columns,
  href: `${DASHBOARD_URL}?${DASHBOARD_PARAM}=${data.id}`
});

// Selectors
// ================================================================
export const getDashboardsState = getStore(DASHBOARD.KEY);

export const getDashboardCount = (store) =>
  getDashboardsState(store)[ORDER_KEY].length || 0;

export const getDashboardsArray = (filter, mapper) => (store) => {
  const state = getDashboardsState(store);
  return getAll(state, filter, (item) => {
    const result = {
      ...item,
      current: state.current === item.id
    };
    return runIfExists(mapper, result) || result;
  });
};

export const getDashboardGrid = () => (store) => {
  const result = getAll(getDashboardsState(store)).map((item) => ({
    ...item,
    columns:
      item.id === DEFAULT_DASHBOARD.id
        ? { length: item.columns.length }
        : getColumnsById(item.columns, ({ id, name }) => ({
            id,
            name
          }))(store)
  }));
  if (result.length < DASHBOARD_MAX_COUNT) {
    result.push(null);
  }
  return [
    result.reduce(
      (acc, item, i) => {
        const j = Math.floor(i / 4);
        if (!acc[j]) {
          acc.push([]);
        }
        if (item) {
          acc[j].push(item);
        }
        return acc;
      },
      [[]]
    ),
    result.length
  ];
};

export const getSavedDashboards = (mapper) => (store) => {
  const result = getDashboardsArray(({ id }) => id > 0, mapper)(store);
  return result;
};

export const getDashboard =
  (id, fallback = BLANK_DASHBOARD) =>
  (store) => {
    return getOne(getDashboardsState(store), id, fallback);
  };

export const getCurrentDashboard = (store) => {
  const state = getDashboardsState(store);
  return state[DATA_KEY][state.current] || {};
};

// Actions
// ================================================================
export const fetchDashboards = createAction(
  DASHBOARD.FETCH,
  async ({ apiv2 }) => {
    const data = await apiv2.getOrCreateItem(DB_DASHBOARDS_KEY, []);
    return data.map(composeDashboard);
  }
);

export const createDashboard = createAction(
  DASHBOARD.CREATE,
  async ({ apiv2, getState }, params) => {
    const dashboards = getSavedDashboards()(getState());
    const maxId = Math.max(...dashboards.map(({ id }) => id));
    const payload = composeDashboard({ ...params, id: maxId + 1 });
    await apiv2.setItem(DB_DASHBOARDS_KEY, [...dashboards, payload]);
    return {
      payload,
      message: `New dashboard: ${payload.name} created.`
    };
  }
);

export const updateDashboard = createAction(
  DASHBOARD.UPDATE,
  async ({ apiv2, getState }, params) => {
    if (params.id !== DEFAULT_DASHBOARD.id) {
      const dashboards = getSavedDashboards((item) =>
        item.id === params.id ? composeDashboard(params) : item
      )(getState());
      await apiv2.setItem(DB_DASHBOARDS_KEY, dashboards);
    }
    return {
      payload: params,
      message: `Dashboard ${params.name} was updated successfully.`
    };
  }
);

export const deleteDashboard = createAction(
  DASHBOARD.DELETE,
  async ({ getState, apiv2 }, { id }) => {
    const dashboards = getSavedDashboards()(getState()).filter(
      (item) => item.id !== id
    );
    await apiv2.setItem(DB_DASHBOARDS_KEY, dashboards);
    return { id };
  }
);

export const setDashboardOrder = createAction(
  DASHBOARD.SORT,
  ({ getState }, { start, end }) =>
    arrayMove(
      getSavedDashboards()(getState()).map(({ id }) => id),
      start,
      end
    )
);

export const saveDashboardOrder = createAction(
  DASHBOARD.SORT,
  async ({ getState, apiv2 }, { start, end }) => {
    const dashboards = arrayMove(getSavedDashboards()(getState()), start, end);
    await apiv2.setItem(DB_DASHBOARDS_KEY, dashboards);
    return {
      payload: dashboards.map(({ id }) => id),
      message: 'Dashboard order saved'
    };
  }
);

export const sortColumns = createAction(
  DASHBOARD.CURRENT_SORT_COLUMNS,
  ({ getState }, { start, end, id }) => {
    const dashboard = getDashboard(id)(getState());
    return {
      id,
      columns: arrayMove(dashboard.columns, start, end)
    };
  }
);

export const saveColumnOrder = createAction(
  DASHBOARD.SAVE_COLUMN_ORDER,
  async ({ getState, api, apiv2 }, { start, end, id }) => {
    if (id === DEFAULT_DASHBOARD.id) {
      const DEFAULT_DASHBOARD = getDashboard(id)(getState());
      return api.get('sort_columns', {
        list: arrayMove(DEFAULT_DASHBOARD.columns, start, end).map(
          (item, i) => ({ id: item, order: i })
        )
      });
    } else {
      const dashboards = getSavedDashboards((item) => {
        if (item.id === id) {
          return {
            ...item,
            columns: arrayMove(item.columns, start, end)
          };
        }
        return item;
      })(getState());
      return apiv2.setItem(DB_DASHBOARDS_KEY, dashboards);
    }
  }
);

// Default module export
// ================================================================
export const SCHEMA = {
  id: '!number',
  name: '!string',
  abbr: '!string',
  label: '!string',
  href: '!string',
  description: 'string',
  current: 'bool',
  width: '!number',
  columns: ['number']
};
export default {
  key: DASHBOARD.KEY,
  // Schema of entity for data normalization
  // ================================================================
  dataschema: {
    [DASHBOARD.FETCH]: SCHEMA,
    [DASHBOARD.CREATE]: SCHEMA,
    [DASHBOARD.UPDATE]: SCHEMA,
    [DASHBOARD.DELETE]: { id: '!number' },
    [DASHBOARD.CURRENT_SORT_COLUMNS]: { id: '!number', columns: ['number'] }
  },
  // Reducer function
  // ================================================================
  reducer: createReducer(
    {
      [DASHBOARD.FETCH]: (state, payload) =>
        payload.reduce((acc, item) => {
          acc[ORDER_KEY].push(item.id);
          acc[DATA_KEY][item.id] = item;
          return acc;
        }, state),
      [DASHBOARD.SORT]: (state, payload) => ({
        ...state,
        [ORDER_KEY]: [DEFAULT_DASHBOARD.id, ...payload]
      }),
      [DASHBOARD.CREATE]: (state, item) => ({
        ...state,
        [DATA_KEY]: {
          ...state[DATA_KEY],
          [item.id]: item
        },
        [ORDER_KEY]: [...state[ORDER_KEY], item.id]
      }),
      [DASHBOARD.UPDATE]: defaultSetOne,
      [DASHBOARD.DELETE]: defaultUnsetOne,
      [COLUMN.FETCH]: updateKey(
        `DATA.${DEFAULT_DASHBOARD.id}.columns`,
        (_, columns) => columns.map(({ id }) => id)
      ),
      [DASHBOARD.CURRENT_SORT_COLUMNS]: updateKey(
        'DATA.$id.columns',
        (_, { columns }) => columns
      ),
      [COLUMN.DELETE]: updateKey('DATA', (state, { id }) => {
        return Object.values(state).reduce((acc, dashboard) => {
          acc[dashboard.id] = {
            ...dashboard,
            columns: dashboard.columns.filter((column) => column !== id)
          };
          return acc;
        }, {});
      })
    },
    {
      current: getDashboardIdFromLocation(),
      [DATA_KEY]: {
        [DEFAULT_DASHBOARD.id]: { ...DEFAULT_DASHBOARD }
      },
      [ORDER_KEY]: [DEFAULT_DASHBOARD.id]
    }
  )
};
