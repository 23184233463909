/**
 * Fetch data via /api/getItem method by [key] if [key] is not defined in Trendolier API storage - set with fallback default value
 * @param {String} key unique string key for storage
 * @param {Object|Array} fallback Fallback value to set if given [key] is unset
 * @returns {Object|Array} Since API does not return any data besides message, simply return [fallback] provided
 */
export async function getOrCreateItem(key, fallback) {
  try {
    const data = await this.get('getItem', { key });
    return data.value ? JSON.parse(data.value) : data || fallback;
  } catch (err) {
    if (err.message !== `Key: ${key} is not initialized. Creating it now...`) {
      throw err;
    }
    await this.post('setItem', {
      key,
      value: fallback
    });
    return fallback;
  }
}

/**
 * Create new Item via /api/setItem method at given [key]
 * @param {String} key unique string key for storage
 * @param {Object|Array} value Value to set, may be Array of Object
 * @returns {Object|Array} Since API does not return any data besides message, simply return [value] provided
 */
export async function setItem(key, value) {
  await this.post('setItem', {
    key,
    value
  });
  return value;
}
