import { cast } from '../../validator';
import { devDebug } from '../../util';

export default function normalizationMiddleware(schema) {
  devDebug(
    `Data normalizer inialized with \n ${Object.keys(schema).join('\n')} keys.`
  );
  return () => (next) => (action) => {
    if (schema[action.type]) {
      if (Array.isArray(action.payload)) {
        devDebug(
          `Actions incoming ${action.type}, ${action.payload.length} items`
        );
        action.payload = action.payload.map((item, i) => {
          return cast(schema[action.type], item, `${action.type}[${i}]`);
        });
      } else {
        devDebug(`Action incoming ${action.type}`);
        action.payload = cast(schema[action.type], action.payload, action.type);
      }
      return next(action);
    } else {
      return next(action);
    }
  };
}
