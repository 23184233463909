// Required libraries
// ================================================================
import React, { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

// Store imports
// ================================================================
import { updateColumnData } from 'shared/Store/Columns';

// Utility functions and hooks
// ================================================================
import useBodyClick from 'trendolizer-ui/build/hooks/useBodyClick';
import { NUM_BOOL_OPTS } from 'shared/const';
import { numBool } from 'shared/proptypes';

// Component imports
// ================================================================
import Switcher from 'trendolizer-ui/build/Switcher';
import TextInput from 'trendolizer-ui/build/TextInput';
import SortingDropdown from 'shared/Components/SortingDropdown';
import DirectionIndicator from 'shared/Components/DirectionIndicator';

// Component declaration
// ================================================================
export default function ColumnDetails(props) {
  const {
    className,
    id,
    sort,
    search,
    direction,
    onBodyClick,
    infinite,
    refreshResults,
    children
  } = props;

  const dispatch = useDispatch();

  // run [onBodyClick] when document is clicked "outside" component
  // ================================================================
  const $root = useRef();
  useBodyClick($root, onBodyClick);

  const onChange = useCallback(
    async ({ name, value }) => {
      await dispatch(updateColumnData({ id, data: { [name]: value } }));
      if (name !== 'infinite' && refreshResults) {
        refreshResults();
      }
    },
    [dispatch, refreshResults, id]
  );

  const [searchValue, setSearchValue] = useState(search || '');

  const onInputChange = useCallback(({ value }) => setSearchValue(value), []);

  const onSearchSubmit = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        onChange({ name: 'search', value: e.target.value });
      }
    },
    [onChange]
  );

  const rootClassName = `${className}-details`;

  return (
    <div ref={$root} role='toolbar' className={rootClassName}>
      <div className={`${rootClassName}-container`}>
        <TextInput
          id={`search-${id}`}
          name='search'
          type='text'
          label='Search for'
          value={searchValue}
          onChange={onInputChange}
          onKeyDown={onSearchSubmit}
          message='Hit "enter" to confirm changes'
        />
        <div className={`${rootClassName}-sort`}>
          <SortingDropdown
            name='sort'
            id={`sort-${id}`}
            value={sort}
            closeOthersOnOpen={false}
            onChange={onChange}
          />
          <DirectionIndicator
            id={`direction-${id}`}
            name='direction'
            onChange={onChange}
            value={direction}
          />
        </div>
        <Switcher
          label='Infinite scroll'
          name='infinite'
          id={`infinite-${id}`}
          value={infinite}
          onChange={onChange}
          options={NUM_BOOL_OPTS}
        />
        {children}
      </div>
    </div>
  );
}

// PropTypes Declaration
// ================================================================
ColumnDetails.propTypes = {
  /** Unique ID of a column */
  id: PT.number.isRequired,
  /** Indicates if infiniteloading is enabled for given column */
  infinite: numBool.isRequired,
  /** CSS class for root element  */
  className: PT.string.isRequired,
  /** Handler for click "outside" */
  onBodyClick: PT.func.isRequired,
  /** Content to render inside component */
  children: PT.node,
  /** Value of column sort property */
  sort: PT.string.isRequired,
  /** Value of column sorting direction property */
  direction: PT.oneOf(['asc', 'desc']).isRequired,
  /** Search criterea for column results */
  search: PT.string.isRequired,
  /** Handler to refresh results after column update */
  refreshResults: PT.func
};
