import './style.css';

// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Hook, utils and const imports
// ================================================================
import { useAction } from '../../hooks';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';

// Component imports
// ================================================================
import FormProvider, { Field } from '../Form';
import StatusMessage from '../StatusMessage';

const initialValues = {
  username: '',
  password: ''
};

// Component declaration
// ================================================================
export default function LoginModal(props) {
  const { session, description, title, onSubmit, onClose } = props;

  const [submitHandler, { loading, error, success, reset, result }] =
    useAction(onSubmit);

  return (
    <Dialog
      id='login-dialog'
      show={!session}
      title={title}
      onClose={onClose}
      maxWidth='380px'
      maxHeight='auto'
      zIndex='calc(var(--layout-z-overlay) + 2)'
      className='LoginModal'
      wrapContent={false}
      footerCloseButton
      footer={
        <Button
          disabled={loading}
          type='submit'
          appearance='accent'
          form='login-form'
          text='Log-in'
        />
      }
    >
      <LinearProgress loading={loading} success={success} />

      <FormProvider
        formId='login-form'
        onSubmit={submitHandler}
        onReset={reset}
        disabled={loading}
        renderForm
        formClassName='layout-grid Dialog-content LoginModal-content'
      >
        {error || success ? (
          <StatusMessage
            className='layout-cell'
            warning={error}
            success={success && result ? result.message : null}
            onClick={reset}
          />
        ) : null}
        {description ? (
          <div className='layout-cell LoginModal-description'>
            {description}
          </div>
        ) : null}
        <div className='layout-cell'>
          <Field
            id='username'
            name='username'
            type='text'
            label='Username'
            required
            placeholder='Your username or email'
            component={TextInput}
            values={initialValues}
          />
        </div>
        <div className='layout-cell'>
          <Field
            id='password'
            name='password'
            label='Password'
            type='password'
            required
            placeholder='Type password here'
            component={TextInput}
          />
        </div>
      </FormProvider>
    </Dialog>
  );
}

// PropTypes declaration
// ================================================================
LoginModal.propTypes = {
  session: PT.bool,
  title: PT.string,
  description: PT.string,
  maxWidth: PT.string,
  hideAfter: PT.number,
  onClose: PT.func,
  onSubmit: PT.func.isRequired
};
