// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Hook, utils and const imports
// ================================================================
import { fullTenantShape } from '../../proptypes';
import { useAction } from '../../hooks';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';
import TextArea from 'trendolizer-ui/build/TextArea';
import ImageUpload from 'trendolizer-ui/build/ImageUpload';
import LinearProgress from 'trendolizer-ui/build/LinearProgress';
import Dialog from 'trendolizer-ui/build/Dialog';
import Button from 'trendolizer-ui/build/Button';

// Component imports
// ================================================================
import FormProvider, { Field } from '../Form';
import StatusMessage from '../StatusMessage';
import { RecipientFormField } from '../RecipientList';

// Styles
// ================================================================
import './style.css';

const EMPTY_ARRAY = [];

const SCHEME = {
  id: '!number',
  fullname: '!string',
  position: 'string',
  image: 'string',
  header: 'string',
  footer: 'string',
  email_bcc: ['recipient']
};

// Component declaration
// ================================================================
export default function TenantSettingsModal(props) {
  const { show, onSubmit, data, onClose } = props;

  // Async form logic handling
  // ================================================================
  const [submitHandler, { loading, error, success, reset }] = useAction(
    onSubmit,
    { validation: SCHEME }
  );

  return (
    <Dialog
      id='tenant-settings-dialog'
      show={show}
      title='Profile info &amp; preferences'
      maxWidth='760px'
      maxHeight='90vh'
      onClose={onClose}
      className='TenantSettings'
      wrapContent={false}
      footerCloseButton
      footer={
        <Button
          disabled={loading}
          type='submit'
          appearance='accent'
          form='tenant-settings-form'
          text='Submit'
        />
      }
    >
      <LinearProgress loading={loading} success={success} />
      <FormProvider
        formId='tenant-settings-form'
        onSubmit={submitHandler}
        onReset={reset}
        disabled={loading}
        values={data}
        renderForm
        formClassName='Dialog-content layout-grid TenantSettings-content'
      >
        {error || success ? (
          <StatusMessage
            className='layout-cell'
            warning={error}
            success={success ? 'Tenant settings updated successfully' : null}
            onClick={reset}
          />
        ) : null}
        <div className='layout-cell' data-col-span={4} data-row-span={3}>
          <ImageUpload
            id='image'
            name='image'
            disabled
            className='TenantSettings-imageeditor'
            description=''
            placeholder='img/user-placeholder.svg'
            onChange={console.log}
          />
        </div>
        <div className='layout-cell' data-col-span={8}>
          <Field
            id='fullname'
            name='fullname'
            type='text'
            label='Fullname'
            component={TextInput}
          />
        </div>
        <div className='layout-cell' data-col-span={8}>
          <Field
            id='position'
            name='position'
            type='text'
            label='Position'
            component={TextInput}
          />
        </div>
        <div
          className='layout-cell'
          data-col-span={8}
          data-col-start={5}
          role='group'
        >
          <Field
            id='email_bcc'
            name='email_bcc'
            className='TenantSettings-recipients'
            label='Additional recipients'
            defaultValue={EMPTY_ARRAY}
            component={RecipientFormField}
            message={`All alerts will be sent to the main email address ${data.email} associated with this account. Use the form above to add an extra recipient.`}
          />
        </div>
        <div className='layout-cell'>
          <Field
            id='header'
            name='header'
            rows={6}
            label='Custom alert/report email header'
            component={TextArea}
          />
        </div>
        <div className='layout-cell'>
          <Field
            id='footer'
            name='footer'
            rows={6}
            label='Custom alert/report email footer'
            component={TextArea}
          />
        </div>
      </FormProvider>
    </Dialog>
  );
}

// PropTypes declaration
// ================================================================
TenantSettingsModal.propTypes = {
  show: PT.bool,
  data: PT.shape(fullTenantShape),
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};
