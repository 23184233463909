// Required libraries
// ================================================================
import React, { useRef, useCallback } from 'react';
import PT from 'prop-types';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import Grid from 'react-virtualized/dist/es/Grid';
import ArrowKeyStepper from 'react-virtualized/dist/es/ArrowKeyStepper';

// Utility functions, constants and hooks
// ================================================================
import {
  SCROLLBAR_CSS_VAR,
  GRID_REBUILD_EVENT,
  RESULT_SCROLL_EVENT,
  GRID_SCROLL_EVENT
} from 'shared/const';
import { useBodyEvent } from 'shared/hooks';
import { getNumericalCssValue, emitCustomEvent } from 'shared/util';

// Component imports
// ================================================================
import Icon from 'trendolizer-ui/build/Icon';
import DragDrop from './DragDrop';
import ColumnCell from './Item';

// Component declaration
// ================================================================
export default function ColumnsGrid(props) {
  const {
    count,
    id,
    dndEnabled,
    colWidth,
    noContentRenderer,
    estimatedColumnSize,
    onDragEnd,
    renderItem,
    renderClone
  } = props;
  const $grid = useRef();

  // Get scrollbar size and gap amount between columns
  // this CSS values used to calculate proper width
  // required by [react-virtualized] component
  // ================================================================
  const SCROLLBAR_SIZE = getNumericalCssValue(SCROLLBAR_CSS_VAR);

  const renderCloneHandler = useCallback(
    (provided, snap, rubric) => {
      return (
        <div
          {...provided.draggableProps}
          className='DashboardScreen-item is-draggable-clone'
        >
          <span className='DragHandle'>
            <Icon icon='drag-handle' />
          </span>
          {renderClone({
            index: rubric.source.index
          })}
        </div>
      );
    },
    [renderClone]
  );

  const cellRenderer = useCallback(
    ({ columnIndex, key, style }) => {
      const children = renderItem({
        index: columnIndex
      });
      return (
        <ColumnCell
          key={key}
          id={children.props.id}
          style={style}
          index={columnIndex}
          dndEnabled={dndEnabled}
          isDragDisabled={!children.props.open}
          className='DashboardScreen-item'
        >
          {children.props.open ? children : children.props.id}
        </ColumnCell>
      );
    },
    [dndEnabled, renderItem]
  );

  const onRecomputeEvent = useCallback(() => {
    if ($grid.current) {
      $grid.current.recomputeGridSize();
    }
  }, []);

  const onScrollEvent = useCallback(async (e) => {
    let $el;
    if ($grid.current) {
      $el = $grid.current._scrollingContainer.querySelector(
        `#column-${e.detail.id}`
      );
      $grid.current.scrollToCell({
        columnIndex:
          parseInt($el.parentElement.getAttribute('data-index')) || 0,
        rowIndex: 0
      });
      setTimeout(() => {
        emitCustomEvent(RESULT_SCROLL_EVENT, { ...e.detail });
        $el.focus();
      }, 150);
    }
  }, []);

  useBodyEvent(GRID_REBUILD_EVENT, onRecomputeEvent);

  useBodyEvent(GRID_SCROLL_EVENT, onScrollEvent);

  const gridProps = {
    id: id,
    cellRenderer: cellRenderer,
    columnWidth: colWidth,
    estimatedColumnSize,
    columnCount: count,
    tabIndex: null,
    rowCount: 1,
    scrollToAlignment: 'center',
    noContentRenderer,
    overscanRowCount: 0,
    overscanColumnCount: 2
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <ArrowKeyStepper columnCount={count} rowCount={1}>
          {({ onSectionRendered, scrollToColumn }) => {
            const additionalProps = {
              scrollToColumn: scrollToColumn,
              onSectionRendered: onSectionRendered,
              rowHeight: height - SCROLLBAR_SIZE,
              height: height,
              width: width
            };

            return dndEnabled ? (
              <DragDrop onDragEnd={onDragEnd} renderClone={renderCloneHandler}>
                {(innerRef) => (
                  <Grid
                    {...gridProps}
                    {...additionalProps}
                    ref={(instance) => {
                      innerRef(instance);
                      $grid.current = instance;
                    }}
                  />
                )}
              </DragDrop>
            ) : (
              <Grid {...gridProps} {...additionalProps} ref={$grid} />
            );
          }}
        </ArrowKeyStepper>
      )}
    </AutoSizer>
  );
}

// PropTypes declaration
// ================================================================
ColumnsGrid.propTypes = {
  /** This is renderer for "clone" item. It used only by 'react-beautifull-dnd' wrapper */
  renderClone: PT.func,
  /** Function that should render each <Cell> contents as well as provide column ID */
  renderItem: PT.func.isRequired,
  /** Unique id for <Grid> element. Mandatory param */
  id: PT.string.isRequired,
  /** Whatever D&D interactions should be incorporated in app flow */
  dndEnabled: PT.bool,
  /** Number of items in <Grid> component */
  count: PT.number.isRequired,
  /** Used to estimate the total width of a Grid before all of its columns have actually been measured */
  estimatedColumnSize: PT.number,
  /** Width of a column in <Grid> measured in pixels */
  colWidth: PT.oneOfType([PT.number, PT.func]).isRequired,
  /** Function that invoked when D&D interaction is done, it should "save" the results */
  onDragEnd: PT.func,
  /** Render placeholder when dashboard has no columns assigned */
  noContentRenderer: PT.func
};
