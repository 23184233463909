export const APP = Object.freeze({
  KEY: 'APP',
  SESSION_STATUS: `APP/SESSION_STATUS`,
  NETWORK_ERROR: `APP/NETWORK_ERROR`,
  SETTINGS_DIALOG_STATUS: `APP/SETTINGS_DIALOG_STATUS`,
  PASSRESET_SENT: `APP/PASSRESET_SENT`,
  SIDEBAR_STATE: `APP/SIDEBAR_STATE`,
  FETCH_SETTINGS: `APP/FETCH_SETTINGS`,
  SET_DELETE_PENDING: `APP/SET_DELETE_PENDING`
});

export const NOTIFICATION = Object.freeze({
  KEY: 'NOTIFICATION',
  ADD: `NOTIFICATION/ADD`,
  HIDE: `NOTIFICATION/HIDE`
});

export const DASHBOARD = Object.freeze({
  KEY: 'DASHBOARD',
  FETCH: `DASHBOARD/FETCH`,
  CREATE: `DASHBOARD/CREATE`,
  UPDATE: `DASHBOARD/UPDATE`,
  DELETE: `DASHBOARD/DELETE`,
  SORT: `DASHBOARD/SORT`,
  CURRENT_SORT_COLUMNS: `DASHBOARD/CURRENT/SORT_COLUMNS`,
  SAVE_COLUMN_ORDER: `DASHBOARD/SAVE_ORDER`
});

export const TENANT = Object.freeze({
  KEY: 'TENANT',
  FETCH: `TENANT/FETCH`,
  LOGIN: `TENANT/LOGIN`,
  LOGOUT: `TENANT/LOGOUT`,
  UPDATE: `TENANT/UPDATE`,
  CREATE: `TENANT/CREATE`
});

export const COLUMN = Object.freeze({
  KEY: 'COLUMN',
  FETCH: `COLUMN/FETCH`,
  FETCH_ONE: `COLUMN/FETCH_ONE`,
  CREATE: `COLUMN/CREATE`,
  UPDATE: `COLUMN/UPDATE`,
  UPDATE_NAME: `COLUMN/UPDATE_NAME`,
  UPDATE_DATA: `COLUMN/UPDATE_DATA`,
  UPDATE_VISIBILITY: `COLUMN/UPDATE_VISIBILITY`,
  DELETE: `COLUMN/DELETE`
});

export const RESULT = Object.freeze({
  KEY: 'RESULT',
  FETCH: `RESULT/FETCH`,
  FETCH_ITEM: `RESULT/FETCH_ITEM`,
  FETCH_DETAILS: `RESULT/FETCH_DETAILS`,
  CLEAR_DETAILS: `RESULT/CLEAR_DETAILS`,
  FETCH_GRAPH_DATA: `RESULT/FETCH_GRAPH_DATA`,
  CLEAR: `RESULT/CLEAR`,
  FAVORITE: `RESULT/FAVORITE`,
  IGNORE: `RESULT/IGNORE`
});

export const USER = Object.freeze({
  KEY: 'USER',
  FETCH: `USER/FETCH`,
  FETCH_ALL: `USER/FETCH_ALL`
});

export const SOURCESET = Object.freeze({
  KEY: 'SOURCESET',
  FETCH: `SOURCESET/FETCH`,
  CREATE: `SOURCESET/CREATE`,
  UPDATE: `SOURCESET/UPDATE`,
  DELETE: `SOURCESET/DELETE`,
  ADD_SOURCE: `SOURCESET/ADD_SOURCE`,
  REMOVE_SOURCE: `SOURCESET/REMOVE_SOURCE`
});

export const SOURCE = Object.freeze({
  KEY: 'SOURCE',
  FETCH: `SOURCE/FETCH`,
  CREATE: `SOURCE/CREATE`,
  DELETE: `SOURCE/DELETE`,
  TEST: `SOURCE/TEST`
});

export const ALERT = Object.freeze({
  KEY: 'ALERT',
  FETCH: `ALERT/FETCH`,
  CREATE: `ALERT/CREATE`,
  UPDATE: `ALERT/UPDATE`,
  UPDATE_STATUS: `ALERT/UPDATE_STATUS`,
  DELETE: `ALERT/DELETE`
});

export const REPORT = Object.freeze({
  KEY: 'REPORT',
  FETCH: `REPORT/FETCH`,
  CREATE: `REPORT/CREATE`,
  UPDATE: `REPORT/UPDATE`,
  UPDATE_STATUS: `REPORT/UPDATE_STATUS`,
  DELETE: `REPORT/DELETE`
});

export const FINGERPRINT = Object.freeze({
  KEY: 'FINGERPRINT',
  TEST_DOMAIN: `FINGERPRINT/TEST_DOMAIN`,
  GET_DOMAINS: `FINGERPRINT/GET_DOMAINS`,
  FETCH_ALERTS: `FINGERPRINT/FETCH_ALERTS`,
  CREATE_ALERT: `FINGERPRINT/CREATE_ALERT`,
  UPDATE_ALERT: `FINGERPRINT/UPDATE_ALERT`,
  DELETE_ALERT: `FINGERPRINT/DELETE_ALERT`
});

export const AUTHOR = Object.freeze({
  KEY: 'AUTHOR',
  FETCH: `AUTHOR/FETCH`
});
