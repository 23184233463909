import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function Indicator(props) {
  const {
    data,
    onClick,
    className,
    activeLabel = 'Active',
    inactiveLabel = 'Inactive'
  } = props;
  return (
    <div
      className={cn('Indicator', className, { 'state-active': !!data })}
      onClick={onClick}
      role='presentation'
    >
      {data ? activeLabel : inactiveLabel}
    </div>
  );
}

assignDisplayName(Indicator);

Indicator.propTypes = {
  /** State to display */
  data: PT.oneOfType([PT.bool, PT.number, PT.string]),
  /** classname to customize element */
  className: PT.string,
  /** Label for indicator when it's active */
  activeLabel: PT.string,
  /** Label for indicator when it's in inactive state */
  inactiveLabel: PT.string,
  /** Optional click handler for a root element */
  onClick: PT.func
};
