import Fuse from 'fuse.js';
import invert from 'trendolizer-ui/build/util/invert';
import pick from 'trendolizer-ui/build/util/pick';
import createAction from './action';
import createReducer, { updateKey, removeKey } from './reducer';
import { getStore } from './selector';
import { COLUMN, RESULT } from './types';
import { getData, getDisplaySettings, getSorting, getColumn } from './Columns';
import { composeQueryParam } from '../DisplaySettings';
import { RESULT_DETAILS_KEY, MAX_RESULT_SOURCE_COUNT } from '../const';
import { decodeHtml, truncateText } from '../util';

const decoder = decodeHtml();

// Preprocess result item
// ================================================================
export const mapResult = (result) => {
  result.ignore = 0;

  if (result.title) {
    result.title = decoder(result.title);
    result.truncatedTitle = truncateText(result.title, 68, 78);
  }
  if (result.description) {
    result.description = decoder(result.description);
    result.truncatedDescription = truncateText(result.description, 140, 154);
  }
  if (result.image) {
    result.image = decoder(result.image);
  }

  if (result.domain && result.domain.includes('youtube.com')) {
    result.type = 'youtube';
  } else if (result.domain && result.domain.includes('facebook.com')) {
    result.type = 'facebook';
  }

  if (result.sources.length > MAX_RESULT_SOURCE_COUNT + 5) {
    result.sourceOverCount = result.sources.length - MAX_RESULT_SOURCE_COUNT;
    result.sources.length = MAX_RESULT_SOURCE_COUNT;
  }

  return result;
};

const updateResultParam = (state, { hash, param, value }) =>
  state.map((item) => {
    if (item.hash === hash) {
      return { ...item, [param]: value };
    }
    return item;
  });

// Selectors
// ================================================================
export const getResultsState = getStore(RESULT.KEY);

export const getResults = (id) => (store) => getResultsState(store)[id] || [];

export const getResultCount = (id) => (store) => getResults(id)(store).length;

export const getResultByIndex = (id, index) => (store) =>
  getResults(id)(store)[index] || {};

export const getFullResult = () => (store) => {
  const data = getResultsState(store)[RESULT_DETAILS_KEY];
  if (!data) return null;
  return { ...data };
};

export const getResultsByText = (query) => (store) => {
  const MIN_CHAR = 4;
  if (query.length < MIN_CHAR) return [];

  const state = getResultsState(store);
  const column_ids = Object.keys(state).filter((k) => k !== RESULT_DETAILS_KEY);

  // Initialize search engine
  // ================================================================
  const fuse = new Fuse([{ title: '' }], {
    minMatchCharLength: MIN_CHAR,
    includeScore: true,
    keys: ['title']
  });

  // Perform initial search in each column results separately
  // Here we also store information about column to reference later
  // ================================================================
  let results = column_ids.reduce((acc, id) => {
    const column = getColumn(id, ({ id, name }) => ({ id, name }))(store);
    const collection = state[id].map((entry) =>
      pick(
        entry,
        'title',
        'truncatedTitle',
        'url',
        'hash',
        'image',
        'columns',
        'found',
        'likes',
        'shares',
        'tweets',
        'comments',
        'views_video',
        'votes_video',
        'comments_video'
      )
    );
    fuse.setCollection(collection);
    const subresult = fuse.search(query).map((entry) => ({ ...entry, column }));
    return acc.concat(subresult);
  }, []);

  // Use hash map to get rid from duplicates cause result could be in number of columns
  // accumulate information about columns given result was found in
  // ================================================================
  results = results.reduce(
    (acc, { item, column, colIndex, refIndex, score }) => {
      if (acc[item.hash]) {
        acc[item.hash].columns.push({ ...column, index: refIndex, colIndex });
      } else {
        acc[item.hash] = {
          item,
          columns: [{ ...column, index: refIndex, colIndex }],
          score
        };
      }
      return acc;
    },
    {}
  );

  // Take hashmap values and sort via score parameter
  // ================================================================
  return Object.values(results).sort((a, b) => a.score - b.score);
};

// Actions
// ================================================================
export const resultClear = createAction(RESULT.CLEAR);

export const fetchResults = createAction(
  RESULT.FETCH,
  async ({ api, getState }, { column, init, offset = 0 }) => {
    const state = getState();
    let result = {
      column,
      offset,
      data: []
    };

    // Return existing results on init fetch
    // ================================================================
    if (init) {
      result.data = getResults(column)(state);
      if (result.data.length) {
        return result;
      }
    }

    // Get column data as Result query
    // ================================================================
    const query = getData(column)(state);

    // Get display settings
    // ================================================================
    const fields = getDisplaySettings(column)(state);

    // Perform request
    // ================================================================
    const data = await api.get('links', {
      ...query,
      offset,
      colID: column,
      fields: composeQueryParam(fields, query.sort)
    });

    result = {
      column,
      offset,
      data: data
        .filter(({ ignore }) => !(ignore === 1 && query.show_ignored !== 1))
        .map(mapResult)
    };

    return result;
  }
);

export const fetchResult = createAction(
  RESULT.FETCH_ITEM,
  async ({ api, getState }, { hash, column }) => {
    const state = getState();

    // Get display settings
    // ================================================================
    const sort = getSorting(column)(state);
    const fields = getDisplaySettings(column)(state);

    const [data] = await api.get('links', {
      hash,
      colID: column,
      fields: composeQueryParam(fields, sort)
    });

    return {
      payload: {
        column,
        hash,
        data: mapResult(data)
      },
      message: `Updated data for: ${hash} result.`
    };
  }
);

export const fetchDetailedResult = createAction(
  RESULT.FETCH_DETAILS,
  async ({ api }, { hash }) => {
    const [data] = await api.get('links', { hash });
    return mapResult(data);
  }
);

export const fetchGraphData = createAction(
  RESULT.FETCH_GRAPH_DATA,
  ({ api }, params) => api.get('graphdata', params)
);

export const clearDetails = createAction(RESULT.CLEAR_DETAILS);

export const favoriteResult = createAction(
  RESULT.FAVORITE,
  async ({ api }, { hash, column, favorite }) => {
    const { success } = await api.get('favorite', {
      hash,
      unfavorite: favorite
    });
    return {
      payload: { column, hash, param: 'favorite', value: invert(favorite) },
      message: success
    };
  }
);

export const ignoreResult = createAction(
  RESULT.IGNORE,
  async ({ api }, { hash, column, ignore }) => {
    const { success } = await api.get('ignore', { hash, unignore: ignore });
    return {
      payload: { column, hash, param: 'ignore', value: invert(ignore) },
      message: success
    };
  }
);

// Module default export
// ================================================================
export default {
  key: RESULT.KEY,
  // Reducer function
  // ================================================================
  reducer: createReducer(
    {
      [RESULT.FETCH]: updateKey('$column', (state, { offset, data }) => {
        if (offset) {
          return [...state].slice(0, offset).concat(data);
        }
        return data;
      }),
      [RESULT.FETCH_ITEM]: updateKey('$column', (state, { hash, data }) =>
        state.map((item) => {
          return item.hash === hash ? data : item;
        })
      ),
      [RESULT.FETCH_DETAILS]: updateKey(RESULT_DETAILS_KEY),
      [RESULT.CLEAR_DETAILS]: updateKey(RESULT_DETAILS_KEY, null),
      [COLUMN.DELETE]: removeKey('$id'),
      [RESULT.CLEAR]: removeKey('$id'),
      [RESULT.FAVORITE]: updateKey('$column', updateResultParam),
      [RESULT.IGNORE]: updateKey('$column', (state, { hash }) =>
        state.filter((item) => item.hash !== hash)
      )
    },
    { [RESULT_DETAILS_KEY]: null }
  )
};
