// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Utility functions and hooks
// ================================================================
import pick from 'trendolizer-ui/build/util/pick';
import {
  MULTI_DISPLAY_PARAMS,
  composePrefixedParam
} from 'shared/DisplaySettings';
import { numBool } from 'shared/proptypes';

// Trendolizer UI components
// ================================================================
import Icon from 'trendolizer-ui/build/Icon';
import Image from 'trendolizer-ui/build/Image';
import ContextMenu from 'trendolizer-ui/build/ContextMenu';

// Other components
// ================================================================
import ResultTable from './Table';
import ResultHeader from './Header';
import ResultFooter from './Footer';

// Style import
// ================================================================
import './style.css';

const RESULT_TABLE_KEYS = MULTI_DISPLAY_PARAMS.reduce(
  (acc, param) => acc.concat(param, ...composePrefixedParam(param)),
  []
);

// Component declaration
// ================================================================
export default function Result(props) {
  const {
    sort,
    id,
    type,
    domain,
    language,
    hash,
    title,
    truncatedTitle,
    truncatedDescription,
    url,
    image,
    found,
    favorite,
    author,
    onDoubleClick,
    contextMenu,
    children,
    tabIndex,
    ...data
  } = props;

  const [ctmenu, setCtmenu] = useState(false);

  const ctMenuHandler = useCallback((e) => {
    e.preventDefault();
    setCtmenu({ clientY: e.clientY, clientX: e.clientX });
  }, []);
  const closeMenuHandler = useCallback(() => setCtmenu(null), []);

  // Get HTML direction attribute based on result language
  // ================================================================
  const dir = language === 'Arabic' ? 'rtl' : 'ltr';

  return (
    <article
      className={cn('Result', { 'is-favorite': favorite })}
      id={id}
      dir={dir}
      data-hash={hash}
      tabIndex={tabIndex}
      onContextMenu={contextMenu ? ctMenuHandler : null}
      onDoubleClick={onDoubleClick}
    >
      {contextMenu && ctmenu ? (
        <ContextMenu
          id={`${id}-ctxmenu`}
          event={ctmenu}
          onClose={closeMenuHandler}
        >
          {children}
        </ContextMenu>
      ) : null}
      <ResultHeader
        menuId={`${id}-actions`}
        value={data[sort]}
        sort={sort}
        found={found}
      >
        {children}
      </ResultHeader>
      <h1 className='Result-title'>
        <a href={url} target='_blank' rel='noreferrer' title={title}>
          {type === 'youtube' ? (
            <Icon icon='youtube' className='Result-type-icon type-youtube' />
          ) : null}
          {truncatedTitle}
        </a>
      </h1>
      {image || truncatedDescription ? (
        <div className='Result-content'>
          {image ? (
            <Image src={image} className='Result-image' ratio='1' />
          ) : null}
          {truncatedDescription ? (
            <div className='Result-description'>{truncatedDescription}</div>
          ) : null}
        </div>
      ) : null}
      <ResultFooter author={author} domain={domain} found={found} />
      <ResultTable
        {...pick(data, ...RESULT_TABLE_KEYS)}
        className='Result-table'
      />
    </article>
  );
}

// PropTypes Declaration
// ================================================================
Result.propTypes = {
  hash: PT.string,
  /** Unique encoded ID of a result */
  id: PT.string.isRequired,
  /** Current sorting value of a Column */
  sort: PT.string.isRequired,
  /** Menu items for actions, if provided */
  children: PT.node,
  /** Action for double-click on result */
  onDoubleClick: PT.func,
  /** Whatever right-click actions should be enabled */
  contextMenu: PT.bool,
  type: PT.string,
  domain: PT.string,
  language: PT.string,
  title: PT.string,
  truncatedTitle: PT.string,
  truncatedDescription: PT.string,
  url: PT.string,
  image: PT.string,
  found: PT.string,
  author: PT.string,
  favorite: numBool,
  tabIndex: PT.string
};
