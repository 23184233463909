export const SORT_REGEX = /^(rate|maxrate|acc|hotness)*(?:_)*([a-z_]+)$/;

export const SORT_PREFIXES = ['rate', 'maxrate', 'hotness', 'acc'];

export const SORT_PARAMS = [
  'found',
  'likes',
  //'shares',
  'comments',
  'tweets',
  'pins',
  'views_video',
  'votes_video',
  'comments_video'
];

export const SORT_FILTERABLE_PARAMS = [
  'likes',
  // 'shares',
  'comments',
  'tweets',
  'pins',
  'views_video',
  'votes_video',
  'comments_video'
];

export const sortStringToObject = (str) => {
  const [, prefix = null, param] = SORT_REGEX.exec(str);
  return {
    prefix,
    param
  };
};

export const composeSortParam = ({ prefix, param }) => {
  if (prefix && param !== 'found') {
    return `${prefix}_${param}`;
  }
  return param;
};

export const sortParamToShort = (stat = '') => {
  let result = stat.replace('_views', '');
  if (stat.includes('maxrate_')) {
    result = `${stat.replace('maxrate_', 'max ')}/hr`;
  } else if (stat.includes('hotness_')) {
    result = stat.replace('hotness_', 'hot ');
  } else if (stat.includes('rate_')) {
    result = `${stat.replace('rate_', '')}/hr`;
  }
  return result;
};
