import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import Icon from '../Icon';

import './style.css';

export default function RowSelector(props) {
  const { className, onClick, selected, value, icon = 'check', title } = props;
  const clickHandler = useCallback(() => onClick(value), [value, onClick]);
  return (
    <button
      onClick={clickHandler}
      title={title}
      role='checkbox'
      aria-checked={selected}
      className={cn('RowSelector', className, { 'state-selected': selected })}
    >
      <Icon icon={icon} />
    </button>
  );
}

assignDisplayName(RowSelector);

RowSelector.propTypes = {
  /** Icon to display */
  icon: PT.string,
  /** Defines whatever item that this component is related to - selected */
  selected: PT.bool,
  /** ClassName for customization */
  className: PT.string,
  /** Click handler - usually used for changing selection state for top-level component */
  onClick: PT.func.isRequired,
  /** Unique item id */
  value: PT.oneOfType([PT.number, PT.string]),
  /**  HTML Title for a <button>  */
  title: PT.string
};
