import illustration from 'img/no_results.svg';

// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Hooks and utils imports
// ================================================================
import { useBoolState } from 'shared/hooks';
import { getColumnsArray } from 'shared/Store/Columns';

// UI Component imports
// ================================================================
import { EntityColumn } from 'shared/Components/Entity';
import Button from 'trendolizer-ui/build/Button';
import EntityBrowserDialog from 'shared/Modules/EntityBrowserDialog';

const mapColumns = ({ id, name, open }) => ({
  id,
  name,
  open
});

export default function NoContentRenderer({ className, onSubmit }) {
  const { state, open, hide } = useBoolState();
  return (
    <div className={className}>
      <img
        className={`${className}-illustration`}
        src={illustration}
        alt='Dashboard is empty'
      />
      <div className={`${className}-text`}>
        <h1 className={`${className}-title`}>
          Looks like this dashboard has no columns assigned.
        </h1>
        <p>
          Dashboard should have columns to display. Try to assign some columns
          to dashboard. <br />
          You will see the difference.
        </p>

        <Button
          appearance='accent'
          className={`${className}-cta`}
          onClick={open}
          text='Assign columns'
        />
      </div>
      <EntityBrowserDialog
        show={state}
        dialogId='column-browser'
        title='Select columns'
        maxHeight='70vh'
        name='columns'
        value={[]}
        selector={getColumnsArray}
        mapper={mapColumns}
        closeOnSubmit
        onChange={onSubmit}
        ItemComponent={EntityColumn}
        onClose={hide}
      />
    </div>
  );
}

NoContentRenderer.propTypes = {
  onSubmit: PT.func.isRequired,
  className: PT.string.isRequired
};
