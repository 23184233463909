import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';
import { cast } from './validator';

const STATE_IDLE = (prev) => ({
  loading: false,
  error: null,
  success: false,
  result: undefined,
  called: prev ? prev.called : 0
});

const STATE_LOADING = ({ called }) => ({
  loading: true,
  error: null,
  success: false,
  result: undefined,
  called: called + 1
});

const STATE_DONE =
  (mixin) =>
  ({ called }) => ({
    loading: false,
    error: null,
    result: undefined,
    called,
    ...mixin
  });

export function useAction(resolver, opts = {}) {
  const { onSuccess, validation } = opts;
  // Inner action state
  // ===========================================================
  const [{ loading, called, error, success, result }, setState] =
    useState(STATE_IDLE);

  const reset = useCallback(() => setState(STATE_IDLE), []);

  // Executor function (params)
  // ===========================================================
  const execute = useCallback(
    async (params) => {
      try {
        // Set state to loading, add called + 1
        // ===========================================================
        setState(STATE_LOADING);

        // Perform request
        // ===========================================================
        const result = await resolver(
          validation ? cast(validation, params) : params
        );

        runIfExists(onSuccess, result);

        // Set state to completed
        // ===========================================================
        setState(STATE_DONE({ result, success: true }));

        // Remember payload
        // ===========================================================
        return result;
      } catch (err) {
        // Set state to error, discard payload
        // ===========================================================
        setState(STATE_DONE({ error: err.toString(), success: false }));

        // Return void
        // ===========================================================
        return undefined;
      }
    },
    [resolver, validation, onSuccess]
  );

  // Return interface
  return [execute, { loading, called, error, result, reset, success }];
}

export const useReduxAction = (handler, override) => {
  const d = useDispatch();
  return useCallback(
    (args) => d(handler(override !== undefined ? override : args)),
    [handler, d, override]
  );
};

export const usePopState = (callback, initState = null) => {
  const [state, setState] = useState(initState);
  const updater = useCallback(
    (e) => {
      setState(callback ? callback(e) : e);
    },
    [callback]
  );
  useEffect(() => {
    window.addEventListener('popstate', updater);
    return () => window.removeEventListener('popstate', updater);
  }, [updater]);

  return [state];
};

export const useBoolState = (init = false) => {
  const [state, setState] = useState(init);

  const open = useCallback((e) => {
    e && e.preventDefault();
    setState(true);
  }, []);

  const hide = useCallback((e) => {
    e && e.preventDefault();
    setState(false);
  }, []);

  return { state, open, hide };
};

export const useSimpleState = (init = null) => {
  const [state, setState] = useState(init);

  const set = useCallback((v) => {
    setState(v);
  }, []);

  const reset = useCallback(() => {
    setState(init);
  }, [init]);

  return [state, set, reset];
};

export const useBodyEvent = (evt, handler) => {
  useEffect(() => {
    document.body.addEventListener(evt, handler);
    return () => {
      document.body.removeEventListener(evt, handler);
    };
  }, [evt, handler]);
};
